export const TAGS = {
  ABANDONED_CART: "2",
  MEMBERS: "3",
  PURCHASE_BUMP_BALANCED_EATING: "4",
  BALANCE_BOOT_CAMP: "5",
  REACTIVATED: "6",
  CANCELED: "7",
};

export const API_KEY = process.env.GATSBY_ACTIVE_CAMPAIGN_API_KEY;
const BASE_URL = "https://kristielengel21626.api-us1.com/api/3";
export const URLS = {
  CONTACTS: `${BASE_URL}/contacts`,
  CONTACT_TAGS: `${BASE_URL}/contactTags`,
  CONTACT_LISTS: `${BASE_URL}/contactLists`,
};
