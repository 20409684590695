import * as React from "react";
import Modal from "react-modal";
import { isMobile, isTablet } from "react-device-detect";
import { FaSpinner } from "@react-icons/all-files/fa/FaSpinner";
import { FaTimes } from "@react-icons/all-files/fa/FaTimes";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import SuccessImage from "../../../static/img/hey_you.png";
import { StaticImage } from "gatsby-plugin-image";
import * as ACTIVE_CAMPAIGN from "../../constants/activeCampaign";
import { sha256 } from "../../utils/crypto";

const isTouch = isMobile || isTablet;

const EmailPromoSchema = Yup.object().shape({
  email_address: Yup.string()
    .email("Invalid email address")
    .required("Required"),
  name: Yup.string().required("Required"),
});

const customStyles = {
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  width: !isTouch ? "50%" : "100%",
  backgroundColor: "#000000",
};

const Popup = ({ open, onCancel, onSuccess }) => {
  const [values, setValues] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [result, setResult] = React.useState(null);

  const execSubmit = React.useCallback((values) => {
    setLoading(true);

    const email = values.email_address.trim();
    const username = values.name.trim();

    fetch("/.netlify/functions/ac-create-or-get-contact", {
      method: "POST",
      body: JSON.stringify({
        email,
        username,
        tag: ACTIVE_CAMPAIGN.TAGS.BALANCE_BOOT_CAMP,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json())
      .then(
        (response) => {
          const event_time = Math.floor(new Date() / 1000);
          const hashedEmail = sha256(email);
          const splitName = username.split(" ");
          const firstName = splitName[0];
          const lastName = splitName.length === 2 ? splitName[1] : "";
          const hashedFirstName = firstName ? sha256(firstName) : null;
          const hashedLastName = lastName ? sha256(lastName) : null;
          const API_VERSION = "14.0";
          const PIXEL_ID = "543947999799706";
          const TOKEN =
            "EABQtcq2ZCgEIBADkwdaXIUcGc6ZCAh25d61hAgLAM2kBgkIwy2sgzkRNGcyH1DFFe2dNRc11aaEXCSuuLZCNzH1KaTGfZAECFECIn4iummZB1tOqrzP6hw3KKj2DU5ZCotApKUzJfcZBVUdHIXzTUpFll67hXZASeumm24lNNbNRtuQ0b41UKl1lCNGuTTtS91QZD";
          const conversionApiUrl = `https://graph.facebook.com/v${API_VERSION}/${PIXEL_ID}/events`;

          const formData = new FormData();
          // const dataString = `[
          //   {
          //     "event_name": "CompleteRegistration",
          //     "event_time": ${event_time},
          //     "action_source": "website",
          //     "event_source_url":
          //       "https://www.kristielengel.com/balance-boot-camp/",
          //     "user_data": {
          //       "em": ["${hashedEmail}"],
          //       "fn": ["${hashedFirstName}"],
          //       "ln": ["${hashedLastName}"],
          //       "client_user_agent": "${navigator.userAgent}",
          //     },
          //     "custom_data": {
          //       "content_name": "Balance Boot Camp",
          //     },
          //   },
          // ]`;
          // formData.append("data", dataString);
          formData.append(
            "data",
            `[{"event_name": "CompleteRegistration", "event_time": ${event_time}, "action_source": "website", "event_source_url": "https://www.kristielengel.com/balance-boot-camp/","user_data": {"em": ["${hashedEmail}"],"fn": ["${hashedFirstName}"],"ln": ["${hashedLastName}"],"client_user_agent": "${navigator.userAgent}"},"custom_data": {"content_name": "Balance Boot Camp"}}]`
          );
          formData.append("access_token", TOKEN);

          fetch(conversionApiUrl, {
            method: "POST",
            body: formData,
          }).catch((err) => {
            console.log("error sending conversion api", conversionApiUrl, err);
          });

          setResult(true);
        },
        (error) => {
          setResult(false);
        }
      )
      .then(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (values) {
      execSubmit(values);
    }
  }, [execSubmit, values]);

  return (
    <Modal
      isOpen={open}
      contentLabel="Promotional Dialog"
      style={{ overlay: { zIndex: 31 }, content: customStyles }}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onCancel}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "column",
          backgroundColor: "#fff",
          padding: "1rem",
          flex: 1,
          position: "relative",
        }}
      >
        <div style={{ position: "absolute", right: 0, top: 0 }}>
          <button
            className="icon-button"
            onClick={onCancel}
            aria-label="Cancel boot camp sign up"
          >
            <FaTimes aria-hidden="true" size={24} color="black" />
          </button>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            paddingTop: isTouch ? "0.5rem" : "2rem",
          }}
        >
          {!!result && (
            <>
              <p
                className="popup-text"
                style={{
                  color: "#CD4F5F",
                  fontSize: 20,
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                Ready...Set...CHECK YOUR EMAIL!
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: isTouch ? "column" : "row",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    maxWidth: "50%",
                    alignSelf: "center",
                  }}
                >
                  <StaticImage
                    src={"../../../static/img/hey_you.png"}
                    loading="lazy"
                    alt="Kristie in the kitchen cutting fruit"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    padding: "1rem",
                  }}
                >
                  <p style={{ fontSize: isTouch ? 12 : 18 }}>
                    You'll receive your first goal in your email within the next
                    few minutes or so! If for some reason, you don't see it,
                    make sure to check your spam!
                  </p>
                  <br />
                  <p style={{ fontSize: isTouch ? 12 : 18 }}>
                    In the meantime, I know you are a SUPER busy woman so
                    staying consistent with your workouts is HARD! For a limited
                    time, you can take 50% off my #BALANCE workout program with
                    code <span style={{ fontWeight: 700 }}>BALANCEBC</span>. All
                    you need is a set of dumbbells to begin! And there's no
                    better day than today to start feeling your best right?!
                  </p>
                </div>
              </div>
              <button
                className="fancy guest-promo-submit"
                type="button"
                onClick={onSuccess}
              >
                Join #Balance at 50%!
              </button>
            </>
          )}
          {result === false && (
            <>
              <p className="popup-text">
                Something went wrong, please try again later.
              </p>
              <button
                className="fancy guest-promo-submit"
                type="button"
                onClick={onCancel}
              >
                Close
              </button>
            </>
          )}
          {!!loading && (
            <>
              <p className="popup-text">Warming up!!!</p>{" "}
              <FaSpinner
                className="fa-spin"
                size={40}
                style={{ marginRight: "1rem", verticalAlign: "middle" }}
              />{" "}
              <div
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  className="promo-success-image"
                  src={SuccessImage}
                  alt={"Kristie pointing at you"}
                />
              </div>
            </>
          )}
          {!loading && result === null && (
            <>
              <p
                className="popup-text"
                style={{
                  fontWeight: 700,
                  textAlign: "center",
                  paddingBottom: "2rem",
                  fontSize: 20,
                  maxWidth: isTouch ? "100%" : "50%",
                  color: "#CD4F5F",
                }}
              >
                Add your name and email below to join my FREE 7 day #BALANCE
                Boot Camp!
              </p>

              <Formik
                initialValues={{
                  name: "",
                  email_address: "",
                }}
                validationSchema={EmailPromoSchema}
                onSubmit={(values) => {
                  setValues({ ...values });
                }}
                render={() => (
                  <Form noValidate={true}>
                    <label htmlFor="guestName">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-end",
                        }}
                      >
                        <strong>Name</strong>
                        <ErrorMessage
                          name="name"
                          component="span"
                          className="field-error"
                        />
                      </div>
                    </label>
                    <Field
                      id="guestName"
                      aria-required="true"
                      name="name"
                      placeholder="Jane Smith"
                    />
                    <label htmlFor="guestEmail">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-end",
                        }}
                      >
                        <strong>Email</strong>
                        <ErrorMessage
                          name="email_address"
                          component="span"
                          className="field-error"
                        />
                      </div>
                    </label>
                    <Field
                      id="guestEmail"
                      aria-required="true"
                      name="email_address"
                      placeholder="email@address.com"
                      type="email"
                    />
                    <button
                      className="fancy guest-promo-submit"
                      type="submit"
                      onClick={() => console.log("submit pressed")}
                    >
                      Sign Up!
                    </button>
                  </Form>
                )}
              />

              <p
                className="meta"
                style={{ marginTop: "1rem", textAlign: "center" }}
              >
                By signing up, you agree to Kristielengel.com's Privacy Policy
                and Terms of Service, and to receive Kristielengel.com offers
                and promotions. We promise to never sell your personal
                information. You may unsubscribe from our emails at any time.
              </p>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default Popup;
